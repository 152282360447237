header {
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 1000;

  .head {
    display: flex;
    align-items: center;

    h1 {
      height: 74px;
      width: 160px;
    }

    .nav-list {
      display: flex;
      margin-left: 60px;

      .el-tabs__header {
        margin-bottom: 0;
      }

      .el-tabs__item {
        font-size: 16px;
        color: var(--text-color);
      }

      .el-tabs__item.is-active,
      .el-tabs__item:hover {
        color: var(--el-color-primary);
      }

      .el-tabs__nav-wrap:after {
        background-color: transparent;
      }
    }

    .login {
      display: flex;
      align-items: center;
      height: fit-content;
      margin-left: auto;

      .login-btn {
        cursor: pointer;
      }

      button {
        margin-left: 20px;
        cursor: pointer;
        width: 105px;
        height: 36px;
        border-radius: 4px;
        color: var(--primary-color);
        border: 1px solid var(--primary-color);
        background-color: transparent;
      }
    }
  }
}

.h2-title {
  display: flex;
  align-items: center;
  font-size: 40px;
  color: var(--text-color);
}

main section {
  padding-top: 60px;
  scroll-margin-top: 60px;
}

.banner {
  display: flex;
  align-items: center;
  padding-top: 0;
  height: 690px;
  background-image: url('../imgs/banner.png');
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;

  .ban-content {
    .title1 {
      font-size: 48px;
    }

    .title2 {
      font-size: 24px;
    }

    .btn {
      margin-top: 40px;
      width: 190px;
      line-height: 60px;
      font-size: 24px;
      color: #fff;
      background-color: var(--primary-color);
    }
  }
}

#func {
  .func-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .func-item {
      display: flex;
      align-items: center;
      width: calc(50% - 20px);
      height: 180px;
      padding-left: 50px;
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      cursor: default;
      transition: all 0.2s;

      .icon {
        width: 48px;
        height: 48px;
        background-image: url('../imgs/func-icon.png');
        background-repeat: no-repeat;
        background-position: center center;
        transition: all 0.2s;
      }

      .right {
        flex: 1;
        margin-top: 86px;
        transition: all 0.2s;

        p {
          color: #A5BAD4;
        }

        .title {
          font-size: 32px;
          font-weight: 700;
        }

        .text {
          margin-top: 30px;
          opacity: 0;
          font-size: 16px;
          line-height: 20px;
          padding-right: 40px;
          transition: all 0.1s 0.2s;
        }
      }
    }

    .func-item:nth-child(1) {
      background-image: url('../imgs/func1-bg1.png');
    }

    .func-item:nth-child(2) {
      background-image: url('../imgs/func2-bg2.png');
    }

    .func-item:nth-child(3) {
      background-image: url('../imgs/func3-bg3.png');
    }

    .func-item:nth-child(4) {
      background-image: url('../imgs/func4-bg4.png');
    }

    .func-item:hover {
      background-image: url('../imgs/func-bg-active.png');

      .icon {
        margin-right: 20px;
      }


      p {
        color: #fff;
      }

      .right {
        margin-top: 0;

        .text {
          margin-top: 10px;
          opacity: 1;
        }
      }
    }

    .func-item:nth-child(1):hover .icon {
      background-image: url('../imgs/func-icon1.png');
    }

    .func-item:nth-child(2):hover .icon {
      background-image: url('../imgs/func-icon2.png');
    }

    .func-item:nth-child(3):hover .icon {
      background-image: url('../imgs/func-icon3.png');
    }

    .func-item:nth-child(4):hover .icon {
      background-image: url('../imgs/func-icon4.png');
    }
  }
}

#data {
  .data-list {
    display: flex;
    height: 240px;
    background-color: #f5f8fd;

    .data-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      flex: 1 0 0;
    }

    .data-item>div:nth-child(1) {
      font-size: 64px;
      color: var(--primary-color);
      .count{
        & span{
          font-size: 36px;
        }
      }
    }

    .data-item>div:nth-child(2) {
      font-size: 24px;
      margin-top: 20px;
    }

    .data-item>div:nth-child(3) {
      font-size: 16px;
      margin-top: 14px;
      color: #717887;
    }
  }
}

#preview {
  .nav-list {
    margin-bottom: 40px;
    padding-bottom: 40px;

    .el-tabs__header {
      margin-right: 20px;
    }

    .el-tabs__nav-scroll {
      display: flex;
      justify-content: center;
    }

    .el-tabs__item {
      padding: 24px 80px;
      height: unset;
      font-size: 24px;
      color: #606982;
    }

    .el-tabs__item.is-active,
    .el-tabs__item:hover {
      color: var(--el-color-primary);
    }

    .el-tabs__item.is-active {
      font-weight: 700;
    }

    .el-tabs__nav-wrap:after {
      height: 1px;
    }
  }

  .nav-item {
    .el-tabs__header {
      .el-tabs__nav-wrap {
        .el-tabs__nav {
          height: calc(64 * 9px);
          overflow-y: auto;
          overflow-x: hidden;
          border-radius: 4px;

          &::-webkit-scrollbar {
            width: 3px;
          }

          &::-webkit-scrollbar-track {
            background: #CDD6E7;
          }

          &::-webkit-scrollbar-thumb {
            background: var(--primary-color);
          }
        }
      }
    }

    .el-tabs__item.is-left.is-active {
      color: #fff;
      background-color: var(--primary-color);
    }

    .el-tabs__active-bar {
      display: none;
    }

    .el-tabs__item {

      width: 240px;
      line-height: 64px;
      padding: 0 20px;
      color: var(--text-color);
      font-size: 20px;
      font-weight: 700;
    }

    .preview-item {
      display: flex;
      align-items: center;
      width: 100%;

      .num {
        margin-right: 30px;
      }

      img {
        width: 24px;
        height: 24px;
        margin-left: auto;
      }
    }
  }

}

#scene {

  background-color: hsl(223, 37%, 96%);

  .scene {
    padding-bottom: 40px;

    .h2-title {
      margin-top: 0;
    }

    .tip {
      font-size: 16px;
    }
  }

  .scene-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;

    .scene-item {
      padding-left: 110px;
      padding-right: 60px;
      flex: 1 0 0;
      height: 280px;
      line-height: 24px;
      padding-top: 70px;
      background-position: center left;
      background-repeat: no-repeat;
      background-size: cover;

      .title {
        font-weight: 700;
        font-size: 32px;
        color: var(--primary-color);
      }

      .text {
        font-size: 16px;
        color: #9E9E9E;
        margin-top: 26px;
      }
    }

    .scene-item:nth-child(1) {
      background-image: url('../imgs/scene-bg1.png');
    }

    .scene-item:nth-child(2) {
      background-image: url('../imgs/scene-bg2.png');
    }

    .scene-item:nth-child(3) {
      background-image: url('../imgs/scene-bg4.png');
    }

    .scene-item4 {
      padding-right: 110px;
      background-image: url('../imgs/scene-bg3.png') !important;
    }

    .scene-item.ad {
      flex: unset;
      width: 190px;
    }
  }
}

#process {
  .process-list {
    display: flex;
    align-items: center;
    justify-content: center;
    column-gap: 20px;

    .process-item {
      display: flex;
      flex-direction: column;
      align-items: center;

      p {
        margin-top: 20px;
        font-size: 24px;
      }
    }
  }
}

#profile {
  .content {
    display: flex;

    .right {
      margin-left: 50px;

      .title {
        font-size: 24px;
        margin-top: 10px;
      }

      .text {
        font-size: 16px;
        line-height: 32px;
      }
    }
  }
}

#contacts {
  .contacts-list {
    display: flex;
    column-gap: 24px;

    .contacts-item {
      flex: 1;
      width: 0;
      font-weight: bold;
      padding: 30px 50px;
      border: 2px solid #F2F4F9;

      .title {
        font-size: 24px;
      }

      .text {
        margin-top: 20px;
        font-size: 28px;
        color: #6891FF;
        word-wrap: break-word;
      }
    }
  }
}

.register {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 60px;
  height: 240px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url('../imgs/register.png');

  .title {
    font-size: 32px;
    color: #fff;
  }

  .btn {
    width: 120px;
    height: 48px;
    background: #FFF;
    font-weight: 700;
    font-size: 16px;
    color: var(--primary-color);
  }
}

footer {
  .foot {
    padding-top: 40px;

    .content {
      display: flex;
      padding-bottom: 40px;
      border-bottom: 1px solid #CDD6E7;

      dl {
        margin-left: 80px;

        dt {
          font-size: 18px;
        }

        dd {
          margin-top: 20px;
          font-size: 16px;
          color: #7D8491;
          cursor: pointer;

          a {
            color: #7D8491;
          }
        }
      }

      dl:first-child {
        margin-left: 40px;
      }

      /* dl:last-child {
        margin-left: auto;
      } */
    }
  }

  .b {
    line-height: 70px;
    text-align: center;
    font-size: 16px;
    color: #7D8491;
  }
}