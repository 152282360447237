header {
  z-index: 1000;
  background-color: #fff;
  position: sticky;
  top: 0;
}

header .head {
  align-items: center;
  display: flex;
}

header .head h1 {
  width: 160px;
  height: 74px;
}

header .head .nav-list {
  margin-left: 60px;
  display: flex;
}

header .head .nav-list .el-tabs__header {
  margin-bottom: 0;
}

header .head .nav-list .el-tabs__item {
  color: var(--text-color);
  font-size: 16px;
}

header .head .nav-list .el-tabs__item.is-active, header .head .nav-list .el-tabs__item:hover {
  color: var(--el-color-primary);
}

header .head .nav-list .el-tabs__nav-wrap:after {
  background-color: #0000;
}

header .head .login {
  align-items: center;
  height: fit-content;
  margin-left: auto;
  display: flex;
}

header .head .login .login-btn {
  cursor: pointer;
}

header .head .login button {
  cursor: pointer;
  width: 105px;
  height: 36px;
  color: var(--primary-color);
  border: 1px solid var(--primary-color);
  background-color: #0000;
  border-radius: 4px;
  margin-left: 20px;
}

.h2-title {
  color: var(--text-color);
  align-items: center;
  font-size: 40px;
  display: flex;
}

main section {
  padding-top: 60px;
  scroll-margin-top: 60px;
}

.banner {
  background-image: url("banner.70984215.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  height: 690px;
  padding-top: 0;
  display: flex;
}

.banner .ban-content .title1 {
  font-size: 48px;
}

.banner .ban-content .title2 {
  font-size: 24px;
}

.banner .ban-content .btn {
  color: #fff;
  background-color: var(--primary-color);
  width: 190px;
  margin-top: 40px;
  font-size: 24px;
  line-height: 60px;
}

#func .func-list {
  flex-wrap: wrap;
  gap: 20px;
  display: flex;
}

#func .func-list .func-item {
  cursor: default;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  align-items: center;
  width: calc(50% - 20px);
  height: 180px;
  padding-left: 50px;
  transition: all .2s;
  display: flex;
}

#func .func-list .func-item .icon {
  background-image: url("func-icon.89f9f70d.png");
  background-position: center;
  background-repeat: no-repeat;
  width: 48px;
  height: 48px;
  transition: all .2s;
}

#func .func-list .func-item .right {
  flex: 1;
  margin-top: 86px;
  transition: all .2s;
}

#func .func-list .func-item .right p {
  color: #a5bad4;
}

#func .func-list .func-item .right .title {
  font-size: 32px;
  font-weight: 700;
}

#func .func-list .func-item .right .text {
  opacity: 0;
  margin-top: 30px;
  padding-right: 40px;
  font-size: 16px;
  line-height: 20px;
  transition: all .1s .2s;
}

#func .func-list .func-item:first-child {
  background-image: url("func1-bg1.eb942f2a.png");
}

#func .func-list .func-item:nth-child(2) {
  background-image: url("func2-bg2.f8c12f98.png");
}

#func .func-list .func-item:nth-child(3) {
  background-image: url("func3-bg3.1ca46fd8.png");
}

#func .func-list .func-item:nth-child(4) {
  background-image: url("func4-bg4.6af0c262.png");
}

#func .func-list .func-item:hover {
  background-image: url("func-bg-active.1d64dea8.png");
}

#func .func-list .func-item:hover .icon {
  margin-right: 20px;
}

#func .func-list .func-item:hover p {
  color: #fff;
}

#func .func-list .func-item:hover .right {
  margin-top: 0;
}

#func .func-list .func-item:hover .right .text {
  opacity: 1;
  margin-top: 10px;
}

#func .func-list .func-item:first-child:hover .icon {
  background-image: url("func-icon1.28542eeb.png");
}

#func .func-list .func-item:nth-child(2):hover .icon {
  background-image: url("func-icon2.f5691ea5.png");
}

#func .func-list .func-item:nth-child(3):hover .icon {
  background-image: url("func-icon3.3ce1dacc.png");
}

#func .func-list .func-item:nth-child(4):hover .icon {
  background-image: url("func-icon4.7ab6cbfe.png");
}

#data .data-list {
  background-color: #f5f8fd;
  height: 240px;
  display: flex;
}

#data .data-list .data-item {
  flex-direction: column;
  flex: 1 0 0;
  justify-content: center;
  align-items: center;
  display: flex;
}

#data .data-list .data-item > div:first-child {
  color: var(--primary-color);
  font-size: 64px;
}

#data .data-list .data-item > div:first-child .count span {
  font-size: 36px;
}

#data .data-list .data-item > div:nth-child(2) {
  margin-top: 20px;
  font-size: 24px;
}

#data .data-list .data-item > div:nth-child(3) {
  color: #717887;
  margin-top: 14px;
  font-size: 16px;
}

#preview .nav-list {
  margin-bottom: 40px;
  padding-bottom: 40px;
}

#preview .nav-list .el-tabs__header {
  margin-right: 20px;
}

#preview .nav-list .el-tabs__nav-scroll {
  justify-content: center;
  display: flex;
}

#preview .nav-list .el-tabs__item {
  height: unset;
  color: #606982;
  padding: 24px 80px;
  font-size: 24px;
}

#preview .nav-list .el-tabs__item.is-active, #preview .nav-list .el-tabs__item:hover {
  color: var(--el-color-primary);
}

#preview .nav-list .el-tabs__item.is-active {
  font-weight: 700;
}

#preview .nav-list .el-tabs__nav-wrap:after {
  height: 1px;
}

#preview .nav-item .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav {
  border-radius: 4px;
  height: 576px;
  overflow: hidden auto;
}

#preview .nav-item .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav::-webkit-scrollbar {
  width: 3px;
}

#preview .nav-item .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav::-webkit-scrollbar-track {
  background: #cdd6e7;
}

#preview .nav-item .el-tabs__header .el-tabs__nav-wrap .el-tabs__nav::-webkit-scrollbar-thumb {
  background: var(--primary-color);
}

#preview .nav-item .el-tabs__item.is-left.is-active {
  color: #fff;
  background-color: var(--primary-color);
}

#preview .nav-item .el-tabs__active-bar {
  display: none;
}

#preview .nav-item .el-tabs__item {
  width: 240px;
  color: var(--text-color);
  padding: 0 20px;
  font-size: 20px;
  font-weight: 700;
  line-height: 64px;
}

#preview .nav-item .preview-item {
  align-items: center;
  width: 100%;
  display: flex;
}

#preview .nav-item .preview-item .num {
  margin-right: 30px;
}

#preview .nav-item .preview-item img {
  width: 24px;
  height: 24px;
  margin-left: auto;
}

#scene {
  background-color: #f1f3f9;
}

#scene .scene {
  padding-bottom: 40px;
}

#scene .scene .h2-title {
  margin-top: 0;
}

#scene .scene .tip {
  font-size: 16px;
}

#scene .scene-list {
  flex-wrap: wrap;
  gap: 20px;
  display: flex;
}

#scene .scene-list .scene-item {
  background-position: 0;
  background-repeat: no-repeat;
  background-size: cover;
  flex: 1 0 0;
  height: 280px;
  padding-top: 70px;
  padding-left: 110px;
  padding-right: 60px;
  line-height: 24px;
}

#scene .scene-list .scene-item .title {
  color: var(--primary-color);
  font-size: 32px;
  font-weight: 700;
}

#scene .scene-list .scene-item .text {
  color: #9e9e9e;
  margin-top: 26px;
  font-size: 16px;
}

#scene .scene-list .scene-item:first-child {
  background-image: url("scene-bg1.edb91234.png");
}

#scene .scene-list .scene-item:nth-child(2) {
  background-image: url("scene-bg2.06148fb0.png");
}

#scene .scene-list .scene-item:nth-child(3) {
  background-image: url("scene-bg4.13d40611.png");
}

#scene .scene-list .scene-item4 {
  padding-right: 110px;
  background-image: url("scene-bg3.7f7c66d7.png") !important;
}

#scene .scene-list .scene-item.ad {
  flex: unset;
  width: 190px;
}

#process .process-list {
  justify-content: center;
  align-items: center;
  column-gap: 20px;
  display: flex;
}

#process .process-list .process-item {
  flex-direction: column;
  align-items: center;
  display: flex;
}

#process .process-list .process-item p {
  margin-top: 20px;
  font-size: 24px;
}

#profile .content {
  display: flex;
}

#profile .content .right {
  margin-left: 50px;
}

#profile .content .right .title {
  margin-top: 10px;
  font-size: 24px;
}

#profile .content .right .text {
  font-size: 16px;
  line-height: 32px;
}

#contacts .contacts-list {
  column-gap: 24px;
  display: flex;
}

#contacts .contacts-list .contacts-item {
  border: 2px solid #f2f4f9;
  flex: 1;
  width: 0;
  padding: 30px 50px;
  font-weight: bold;
}

#contacts .contacts-list .contacts-item .title {
  font-size: 24px;
}

#contacts .contacts-list .contacts-item .text {
  color: #6891ff;
  word-wrap: break-word;
  margin-top: 20px;
  font-size: 28px;
}

.register {
  background-image: url("register.f5c39eaa.png");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 240px;
  margin-top: 60px;
  display: flex;
}

.register .title {
  color: #fff;
  font-size: 32px;
}

.register .btn {
  width: 120px;
  height: 48px;
  color: var(--primary-color);
  background: #fff;
  font-size: 16px;
  font-weight: 700;
}

footer .foot {
  padding-top: 40px;
}

footer .foot .content {
  border-bottom: 1px solid #cdd6e7;
  padding-bottom: 40px;
  display: flex;
}

footer .foot .content dl {
  margin-left: 80px;
}

footer .foot .content dl dt {
  font-size: 18px;
}

footer .foot .content dl dd {
  color: #7d8491;
  cursor: pointer;
  margin-top: 20px;
  font-size: 16px;
}

footer .foot .content dl dd a {
  color: #7d8491;
}

footer .foot .content dl:first-child {
  margin-left: 40px;
}

footer .b {
  text-align: center;
  color: #7d8491;
  font-size: 16px;
  line-height: 70px;
}
/*# sourceMappingURL=index.d083c404.css.map */
